import { Video } from '../types/data'
import { ReducerData } from './../types/reducers'

export const isVideoFavorite = (video: Video, data: ReducerData): boolean => {
    const videoIds = video.url.substring(video.url.indexOf('libfdk'), video.url.length).split('/')
    return (
        data.favorites.find((favoriteVideo) => {
            const favoriteVideoIds = favoriteVideo.url.substring(favoriteVideo.url.indexOf('libfdk'), favoriteVideo.url.length).split('/')

            return videoIds[4] === favoriteVideoIds[4]
        }) !== undefined
    )
}

export const getRandomImage = (path?: string): string => {
    const all_image = [
        '/resources/images/eventi_cross/33-BJF-2017-VISUAL-2_1.jpg',
        '/resources/images/eventi_cross/221328_nykoebing-roervig-jazz-festival-2019-groen.jpg',
        '/resources/images/eventi_cross/65394392_2360457124231367_7624649574216368128_n.jpg',
        '/resources/images/eventi_cross/Ancona-Jazz-2019.jpg',
        '/resources/images/eventi_cross/barcelona-jazz-festival.jpg',
        '/resources/images/eventi_cross/cropped-etnica2019promopage.jpg',
        '/resources/images/eventi_cross/jazz-nizza-2019.jpg',
        '/resources/images/eventi_cross/large-magnum.jpg'
    ]

    const randomImage = all_image[Math.floor(Math.random() * all_image.length)]
    if (path !== undefined) {
        return path + randomImage
    } else {
        return randomImage
    }
}

// 'http://jazz360.22hbg.eu/video/Sigal/02_Rien_qu\'_des_yeux_pour_toi_264-30.mp4'
export const formatVideoUrl = async (
    video: Video
): Promise<{
    original: string
    converted: string | null
}> => {
    let baseUrl = 'http://jazz360.22hbg.eu/video/'

    baseUrl += video.band_name.replaceAll(' ', '_').replaceAll("'", '').toLowerCase() + '/'

    baseUrl += video.video_name.replaceAll(' ', '_').replaceAll("'", '').toLowerCase()

    //const videoExists = await checkMedia(`${baseUrl}-30.mp4`)

    return {
        original: `${baseUrl}.mp4`,
        converted: `${baseUrl}-30.mp4`
    }
}

export const checkMedia = async (url: string): Promise<string | null> => {
    try {
        const result = await fetch(url, { method: 'HEAD' })
        
        if (result.ok) {
            return url
        } else {
            return null
        }
    } catch (e) {
        return null
    }
}

import React, { useState } from 'react'
import { getRandomImage } from '../utils/functions'
import Modal from './Modal'
import VideoDetails from './Video/VideoDetails'

interface Props {
    video: any
    posterUrl: string,
    position: number
}

const TopTenCard: React.FC<Props> = (props) => {
    const [toggleModal, setToggleModal] = useState<boolean>(false)

    const handleToggleModal = () => {
        setToggleModal(true)
    }
    const closeModal = () => {
        setToggleModal(false)
    }

    let image = getRandomImage('..')
    if (props.video.cover.url !== null) {
        image = 'https://mediavr-api.22hbg.net' + props.video.cover.url
    }
    const netflixUrl = false
    let imagePost = getRandomImage('..')
    if (props.posterUrl !== null && props.posterUrl !== 'https://mediavr-api.22hbg.net/null') {
        imagePost = props.posterUrl
    }

    return (
        <div style={{
            display: "flex",
            justifyContent: "flex-start",
            position: "relative"
        }}>
            
            <div onClick={() => handleToggleModal()} className={'movieShowcase__container--movie'} style={{maxWidth: 366}}>
            <p className="positionNumber">{props.position}</p>
                <img src={image} className="movieShowcase__container--movie-image" />
                <div className="movieShowcase__container--movie-overtitle">
                    <span className="movieShowcase__container--movie-overtitle-cat">{props.video.event_name}</span>
                    <span className="movieShowcase__container--movie-overtitle-name">
                        <b>{props.video.band_name}</b>
                    </span>
                    <span className="movieShowcase__container--movie-overtitle-name">
                        {props.video.video_name}
                    </span>
                    {/*<span className="movieShowcase__container--movie-overtitle-name"></span>*/}
                </div>
            </div>
            <div></div>
            <Modal show={toggleModal} videoImage={props.video} modalClosed={closeModal} cover={image}>
                <VideoDetails video={props.video} status={toggleModal} />
            </Modal>
        </div >
    )
}

export default TopTenCard

import React from 'react'
import playIcon from '../../resources/images/play-button.svg'
import addIcon from '../../resources/images/add.svg'

interface Props {
    video: any
    artist: string
}

const ArtistDetails: React.FC<Props> = (props) => {
    return (
        <div>
            <div className="modal__container">
                <h1 className="modal__title">{props.artist}</h1>

                <p className="modal__overview">{props.video.video_name}</p>
                <button className="modal__btn modal__btn--red">
                    <img className="modal__btn--icon" src={playIcon} />
                    Play
                </button>
                <button className="modal__btn">
                    <img className="modal__btn--icon" src={addIcon} />
                    My List
                </button>
            </div>
        </div>
    )
}

export default ArtistDetails

import React from 'react'
import Categories from './CategoriesSections'
import ArtistsSection from './ArtistsSection'
import Events from './EventsSection'
import TopTen from './TopTenSection'
import { useSelector } from 'react-redux'
import { Reducers, ReducerGraphics } from '../../types/reducers'
import Loader from '../../components/Loader'

const HomePage: React.FC = () => {
    const graphics = useSelector<Reducers, ReducerGraphics>((store) => store.graphics)

    if (graphics.dataLoader) {
        return (
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: 200
                }}
            >
                <Loader />
                <h3
                    style={{
                        marginTop: 50,
                        color: '#fff'
                    }}
                >
                    Caricamento in corso...
                </h3>
            </div>
        )
    }

    return (
        <div className="movieShowcase">
            <Categories />
            <TopTen />
            <Events />
            {/* <ArtistsSection /> */}
        </div>
    )
}

export default HomePage

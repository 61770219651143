import React from 'react'
import { useSelector } from 'react-redux'
import { ReducerData, ReducerGraphics, Reducers } from '../types/reducers'
import VideoGenre from '../components/VideoGenre'
import Loader from '../components/Loader'
import { Video } from '../types/data'
import Slider from "react-slick";
import VideoCategories from '../components/VideoCategories'

const FavoritesPage: React.FC = () => {
    const data = useSelector<Reducers, ReducerData>((store) => store.data)
    const graphics = useSelector<Reducers, ReducerGraphics>((store) => store.graphics)

    const groupedVideos: {
        videos: Video[]
        categories: string
    }[] = []

    for (let i = 0; i < data.favorites.length; i++) {
        const video = data.favorites[i]
        const foundIndex = groupedVideos.findIndex((group) => group.categories === video.categories.join(','))
        if (foundIndex === -1) {
            groupedVideos.push({
                videos: [video],
                categories: video.categories.join(',')
            })
        } else {
            groupedVideos[foundIndex].videos.push(video)
        }
    }

    const videoArr = groupedVideos.map((group) => {
        let categories = group.categories

        if (group.categories.split(',').length > 1) {
            categories = group.categories.split(',').join(', ')
        }

        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: group.videos.length > 5 ? 5 : group.videos.length,
            slidesToScroll: group.videos.length > 5 ? 5 : group.videos.length,
            swipeToSlide: false,
            responsive: [
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: group.videos.length > 4 ? 4 : group.videos.length,
                        slidesToScroll: group.videos.length > 4 ? 4 : group.videos.length,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: group.videos.length > 3 ? 3 : group.videos.length,
                        slidesToScroll: group.videos.length > 3 ? 3 : group.videos.length,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: group.videos.length > 2 ? 2 : group.videos.length,
                        slidesToScroll: group.videos.length > 2 ? 2 : group.videos.length,
                        initialSlide: group.videos.length > 2 ? 2 : group.videos.length
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <div key={categories} className="containerScroll standardVideo">
                <h1 className="movieShowcase__heading">{categories}</h1>
                <Slider {...settings}>
                    {group.videos.map((vid: any, k: number) => {
                        return <VideoCategories key={k} video={vid} />
                    })}
                </Slider>
            </div>
        )
    })

    return (
        <>
            <h1 className="movieShowcase__heading" style={{fontSize: 36}}>I miei preferiti</h1>
            {graphics.favoritesLoader ? (
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: 200
                    }}
                >
                    <Loader />
                    <h3
                        style={{
                            marginTop: 50,
                            color: '#fff'
                        }}
                    >
                        Caricamento in corso...
                    </h3>
                </div>
            ) : (
                <div>{videoArr}</div>
            )}
        </>
    )
}

export default FavoritesPage

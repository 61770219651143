import React from 'react'
import { NavLink } from 'react-router-dom'

interface Props {
    link: string
    exact: boolean
}

const NavigationItem: React.FC<Props> = (props) => (
    <NavLink className="navigation__container-link" exact={props.exact} to={props.link}>
        {props.children}
    </NavLink>
)

export default NavigationItem

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
// Import main sass file to apply global styles
import './resources/sass/style.scss'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { persistor, store } from './store/reducers'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import './resources/js/dl8'

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={<div />} persistor={persistor}>
            <ReactNotification />
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('app')
)

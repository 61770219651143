import React from 'react'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { ReducerData, Reducers } from '../types/reducers'
import VideoGenreFiltered from '../components/VideoGenreFiltered'
import { Video } from '../types/data'
import Slider from 'react-slick'
import VideoCategories from '../components/VideoCategories'

const ArtistPage: React.FC = () => {
    const data = useSelector<Reducers, ReducerData>((store) => store.data)

    const location = useLocation()

    const passedData = location.state ? (location.state as any) : undefined

    const filteredData = data.data.all_videos.filter((video) => video.info.artist_name.replace(' ', '-') === passedData.artistName)

    const groupedVideos: {
        videos: Video[]
        event: string
    }[] = []

    for (let i = 0; i < filteredData.length; i++) {
        const video = filteredData[i]
        const foundIndex = groupedVideos.findIndex((group) => group.event === video.event_name)
        if (foundIndex === -1) {
            groupedVideos.push({
                videos: [video],
                event: video.event_name
            })
        } else {
            groupedVideos[foundIndex].videos.push(video)
        }
    }

    const videoArr = groupedVideos.map((group) => {
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: group.videos.length > 6 ? 6 : group.videos.length,
            swipeToSlide: false,
            responsive: [
                {
                    breakpoint: 1560,
                    settings: {
                        slidesToShow: group.videos.length > 5 ? 5 : group.videos.length,
                        infinite: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: group.videos.length > 4 ? 4 : group.videos.length,
                        infinite: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: group.videos.length > 3 ? 3 : group.videos.length
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: group.videos.length > 2 ? 2 : group.videos.length
                    }
                }
            ]
        }

        return (
            <div key={group.event} className="containerScroll standardVideo">
                <h1 className="movieShowcase__heading">{group.event}</h1>
                <Slider {...settings}>
                    {group.videos.map((vid: any, k: number) => {
                        return <VideoCategories key={k} video={vid} />
                    })}
                </Slider>
            </div>
        )
    })

    return (
        <>
            <h1 className="movieShowcase__heading momento" style={{ fontSize: 36 }}>
                {filteredData[0].info.artist_name}
            </h1>
            {videoArr}
            {/* <h1 className="artistGrid">
                {filteredData.map((video, i) => (
                    <VideoGenreFiltered key={i} posterUrl={'https://mediavr-api.22hbg.net' + video.cover.url} video={video} />
                ))}
            </h1> */}
        </>
    )
}

export default ArtistPage

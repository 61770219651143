import React from 'react'
import EventFiltered from '../components/EventFiltered'

// Event images
import event1 from '../resources/images/eventi_cross/33-BJF-2017-VISUAL-2_1.jpg'
import event2 from '../resources/images/eventi_cross/221328_nykoebing-roervig-jazz-festival-2019-groen.jpg'
import event3 from '../resources/images/eventi_cross/65394392_2360457124231367_7624649574216368128_n.jpg'
import event4 from '../resources/images/eventi_cross/Ancona-Jazz-2019.jpg'
import event5 from '../resources/images/eventi_cross/barcelona-jazz-festival.jpg'
import event6 from '../resources/images/eventi_cross/cropped-etnica2019promopage.jpg'
import event7 from '../resources/images/eventi_cross/jazz-nizza-2019.jpg'
import event8 from '../resources/images/eventi_cross/large-magnum.jpg'
import EventsSection from './home/EventsSection'

const EventsPage: React.FC = () => {
    const all_events = [
        {
            event_name: 'Evento 1',
            url_image: event1
        },
        {
            event_name: 'Evento 2',
            url_image: event2
        },
        {
            event_name: 'Evento 3',
            url_image: event3
        },
        {
            event_name: 'Evento 4',
            url_image: event4
        },
        {
            event_name: 'Evento 4',
            url_image: event5
        },
        {
            event_name: 'Evento 4',
            url_image: event6
        },
        {
            event_name: 'Evento 4',
            url_image: event7
        },
        {
            event_name: 'Evento 4',
            url_image: event8
        }
    ]

    const events = all_events.map((event: any, i: number) => <EventFiltered key={i} posterUrl={event.url_image} />)

    return (
        <div className="movieShowcase">
            <EventsSection />
        </div>
    )
}

export default EventsPage

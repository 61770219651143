import React, { useEffect, useState } from 'react'
import Tabs from './Tabs'
import { store } from 'react-notifications-component'
import { db } from '../Firebase'
import { useDispatch, useSelector } from 'react-redux'
import { Reducers, ReducerData, ReducerUser, ReducerGraphics } from '../types/reducers'
import { useLocation } from 'react-router'
import favoriteIcon from '../resources/images/favorite.svg'
import noFavoriteIcon from '../resources/images/no-favorite.svg'
import headerEvents from '../resources/images/sfondoEvento.jpg'
import { Video } from '../types/data'

import { fetchUserFavorites } from '../store/actions/thunk_actions'
import { getRandomImage, isVideoFavorite, formatVideoUrl } from '../utils/functions'
import Loader from './Loader'
import { usePrevious } from '../utils/hooks'

const Header: React.FC = () => {
    const [favoriteLoading, setFavoriteLoading] = useState<boolean>(false)

    const [currentVideoData, setCurrentVideoData] =
        useState<{
            video: Video
            original: string
            converted: string | null
        } | null>(null)
    const [videoKey, setVideoKey] = useState<string>('')

    const data = useSelector<Reducers, ReducerData>((store) => store.data)
    const user = useSelector<Reducers, ReducerUser>((store) => store.user)
    const graphics = useSelector<Reducers, ReducerGraphics>((store) => store.graphics)

    const location = useLocation()
    const dispatch = useDispatch()

    const previousLoader = usePrevious(graphics.dataLoader)
    const previousPathName = usePrevious(location.pathname)

    useEffect(() => {
        if (
            (previousLoader !== undefined && previousLoader === true && graphics.dataLoader === false) ||
            (previousPathName !== undefined && previousPathName !== location.pathname && graphics.dataLoader === false)
        ) {
            const lastVideo = data.data.all_videos[data.data.all_videos.length - 1]

            if (location.pathname === '/artisti' || lastVideo === undefined) {
                const video = data.data.all_videos[Math.floor(Math.random() * data.data.all_videos.length)]
                checkUrls(video)
                return
            }

            checkUrls(lastVideo)
            return
        }

        if (previousLoader === undefined && graphics.dataLoader === false) {
            const lastVideo = data.data.all_videos[data.data.all_videos.length - 1]
            checkUrls(lastVideo)
            return
        }
    }, [graphics.dataLoader, location.pathname])

    const checkUrls = async (video: Video) => {
        const urls = await formatVideoUrl(video)
        setCurrentVideoData({
            ...urls,
            video
        })
        setVideoKey('key' + Math.random())

        setTimeout(() => {
            setVideoKey('key' + Math.random())
        }, 10)
    }

    const addToFavorites = (video: Video) => {
        setFavoriteLoading(true)
        const urlSubstringPos = video.url.indexOf('libfdk')
        const idArr = video.url.substring(urlSubstringPos, video.url.length).split('/')
        const videoData = {
            videoId: idArr[4],
            ...video,
            uid: user.subscription.id
        }
        db.collection('preferiti')
            .doc(videoData.videoId.toString())
            .set(videoData)
            .then(async () => {
                await dispatch(fetchUserFavorites())
                setFavoriteLoading(false)
                store.addNotification({
                    title: 'Lista preferiti',
                    message: 'Aggiunto il video ' + video.video_name + ' alla mia lista',
                    type: 'warning',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                })
            })
            .catch((error) => {
                setFavoriteLoading(false)
                store.addNotification({
                    title: 'Errore',
                    message: error.message,
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                })
            })
    }

    const removeToFavorites = (video: Video) => {
        setFavoriteLoading(true)
        const idArr = video.url.substring(video.url.indexOf('libfdk'), video.url.length).split('/')
        const videoData = {
            videoId: idArr[4],
            ...video,
            uid: user.subscription.id
        }
        db.collection('preferiti')
            .doc(videoData.videoId.toString())
            .delete()
            .then(async () => {
                await dispatch(fetchUserFavorites())
                setFavoriteLoading(false)
                store.addNotification({
                    title: 'Lista preferiti',
                    message: 'Rimosso il video ' + video.video_name + ' dalla mia lista',
                    type: 'warning',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                })
            })
            .catch((error) => {
                setFavoriteLoading(false)
                store.addNotification({
                    title: 'Errore',
                    message: error.message,
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                })
            })
    }

    const getBackgroundStyle = () => {
        if (location.pathname === '/artisti' || location.pathname === '/') {
            let artist_url_image = getRandomImage()

            if (currentVideoData && currentVideoData.video.cover.url !== null) {
                artist_url_image = currentVideoData.video.cover.url
            }

            return {
                backgroundSize: 'cover',
                backgroundImage: `url(https://mediavr-api.22hbg.net/${artist_url_image})`,
                backgroundPosition: 'center'
            }
        }

        return {
            display: 'none'
        }
    }

    if (location.pathname == '/eventi') {
        return (
            <header className="header" style={{ backgroundImage: `url('${headerEvents}')`, backgroundSize: 'cover', minHeight: 600 }}>
                <div className="header__eventMetaContainer">
                    <h2 className="header__eventMetaContainer-eventTitle">Jazz Au Theatre</h2>
                    <p className="header__eventMetaContainer-eventButton">GUARDA ORA</p>
                    <p className="header__eventMetaContainer-eventSummary">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                        sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet.
                    </p>
                </div>
            </header>
        )
    }

    if (graphics.dataLoader || data.data.all_videos.length === 0 || !currentVideoData) {
        return null
    }

    const getCurrentVideo = () => {
        if (currentVideoData.converted) {
            return currentVideoData.converted
        }

        return currentVideoData.original
    }

    const backgroundStyle = getBackgroundStyle()

    const membersArray =
        currentVideoData && currentVideoData.video.info
            ? currentVideoData.video.info.members.map((member: any, i: number) => {
                  return (
                      <li key={`headerData${i}${member.name}`} className="modal__dataCol-dataCont--description">
                          {member.name.toUpperCase()} {' - '} {member.role}
                      </li>
                  )
              })
            : []

    if (location.pathname === '/' || location.pathname === '/artisti') {
        const videoUrl = getCurrentVideo()
        return (
            <header style={backgroundStyle} className="header">
                <div className="header__container">
                    <div className="modal__videoCol">
                        <dl8-video
                            key={videoKey}
                            author="Cross360"
                            format="STEREO_360_TB"
                            style={{
                                display: 'flex',
                                flex: 1
                            }}
                            preload="auto"
                            fps={currentVideoData.converted === null ? '60' : '30'}
                        >
                            <source src={videoUrl} type="video/mp4" />
                        </dl8-video>
                    </div>

                    <div className="modal__dataCol">
                        <div className="modal__dataCol-dataCont">
                            <div className="modal__dataCol-dataCont--metaCont">
                                <div className="modal__dataCol-dataCont--metaCont---titleCont">
                                    {location.pathname === '/' ? (
                                        <h2 className="modal__dataCol-dataCont--metaCont---titleCont----catTitle">SCELTI PER VOI</h2>
                                    ) : null}

                                    <h1 className="modal__dataCol-dataCont--metaCont---titleCont----title">
                                        {currentVideoData.video.video_name} di{' '}
                                        <b>
                                            {currentVideoData.video.info && currentVideoData.video.info.artist_name
                                                ? currentVideoData.video.info.artist_name
                                                : ''}
                                        </b>
                                    </h1>
                                </div>
                                <div className="modal__dataCol-dataCont--buttons">
                                    {favoriteLoading ? (
                                        <Loader />
                                    ) : isVideoFavorite(currentVideoData.video, data) ? (
                                        <button className="buttonAdd" onClick={() => removeToFavorites(currentVideoData.video)}>
                                            <img src={favoriteIcon} />
                                        </button>
                                    ) : (
                                        <button className="buttonAdd" onClick={() => addToFavorites(currentVideoData.video)}>
                                            <img src={noFavoriteIcon} />
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                            <Tabs
                                id="home"
                                tabs={[
                                    {
                                        label: 'Descrizione',
                                        content: (
                                            <div>
                                                <p className="modal__dataCol-dataCont--description">
                                                    {currentVideoData.video.info && currentVideoData.video.info.text
                                                        ? currentVideoData.video.info.text.split(/(?:\r\n|\r|\n)/g).map((p) => {
                                                              return (
                                                                  <>
                                                                      {p}
                                                                      <br />
                                                                  </>
                                                              )
                                                          })
                                                        : ''}
                                                </p>
                                            </div>
                                        )
                                    },
                                    {
                                        label: 'Band',
                                        content: <div>{membersArray}</div>
                                    },
                                    {
                                        label: 'Location',
                                        content: (
                                            <div>
                                                <p className="modal__dataCol-dataCont--description">
                                                    {currentVideoData.video.info && currentVideoData.video.info.date ? currentVideoData.video.info.date : ''}
                                                </p>
                                                <p className="modal__dataCol-dataCont--description">
                                                    {currentVideoData.video.info && currentVideoData.video.info.city ? currentVideoData.video.info.city : ''}
                                                </p>
                                                <p className="modal__dataCol-dataCont--description">
                                                    {currentVideoData.video.info && currentVideoData.video.info.location
                                                        ? currentVideoData.video.info.location
                                                        : ''}
                                                </p>
                                            </div>
                                        )
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </header>
        )
    }

    return null
}

export default Header

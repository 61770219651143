import React from 'react'
import event1 from '../../resources/images/eventi_cross/33-BJF-2017-VISUAL-2_1.jpg'
import event2 from '../../resources/images/eventi_cross/221328_nykoebing-roervig-jazz-festival-2019-groen.jpg'
import event3 from '../../resources/images/eventi_cross/65394392_2360457124231367_7624649574216368128_n.jpg'
import event4 from '../../resources/images/eventi_cross/Ancona-Jazz-2019.jpg'
import event5 from '../../resources/images/eventi_cross/barcelona-jazz-festival.jpg'
import event6 from '../../resources/images/eventi_cross/cropped-etnica2019promopage.jpg'
import event7 from '../../resources/images/eventi_cross/jazz-nizza-2019.jpg'
import event8 from '../../resources/images/eventi_cross/large-magnum.jpg'
import EventsComp from '../../components/EventsComp'
import Slider from "react-slick";

const EventsSection: React.FC = () => {


    const events = [
        {
            event_name: 'Evento 1',
            url_image: event1
        },
        {
            event_name: 'Evento 2',
            url_image: event2
        },
        {
            event_name: 'Evento 3',
            url_image: event3
        },
        {
            event_name: 'Evento 4',
            url_image: event4
        },
        {
            event_name: 'Evento 4',
            url_image: event5
        },
        {
            event_name: 'Evento 4',
            url_image: event6
        },
        {
            event_name: 'Evento 4',
            url_image: event7
        },
        {
            event_name: 'Evento 4',
            url_image: event8
        }
    ]

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: events.length > 6 ? 6 : events.length,
        swipeToSlide: false,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: events.length > 5 ? 5 : events.length,
                    infinite: true,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: events.length > 4 ? 4 : events.length,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: events.length > 3 ? 3 : events.length,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    return (
        <div className="containerScroll_events">
            <h1 className="movieShowcase__heading">Eventi</h1>
            <Slider {...settings}>
                {events.map((event: any, i: number) => (
                    <EventsComp key={`homeEvent${i}`} posterUrl={event.url_image} />
                ))}
            </Slider>
        </div>
    )
}

export default EventsSection

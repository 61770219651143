import React, { useState } from 'react'

interface Props {
    id: string
    tabs: {
        label: string
        content: React.ReactElement
    }[]
}

const Tabs: React.FC<Props> = (props) => {
    const [activeTab, setActiveTab] = useState<string>(props.tabs[0].label)

    return (
        <div>
            <ol className="tab-list">
                {props.tabs.map((tab) => {
                    return (
                        <li
                            key={`${props.id}-bar-${tab.label}`}
                            className={`tab-list-item${activeTab === tab.label ? ' tab-list-active' : ''}`}
                            onClick={() => setActiveTab(tab.label)}
                        >
                            {tab.label}
                        </li>
                    )
                })}
            </ol>
            <div>
                {props.tabs.map((tab) => {
                    if (tab.label !== activeTab) return null
                    return <div key={`${props.id}-${tab.label}-content`}>{tab.content}</div>
                })}
            </div>
        </div>
    )
}

export default Tabs

import firebase from 'firebase/app'
import 'firebase/firestore'

const config = {
    apiKey: 'AIzaSyCe_pG_tcmk7kvP_QD4NlLxXskaoRAsPQo',
    authDomain: 'jazz360-52f26.firebaseapp.com',
    databaseURL: 'https://jazz360-52f26.firebaseio.com',
    projectId: 'jazz360-52f26',
    storageBucket: 'jazz360-52f26.appspot.com',
    messagingSenderId: '852803321510',
    appId: '1:852803321510:web:5221375fc76a1f7c777e67'
}
firebase.initializeApp(config)

const db = firebase.firestore()

export { db }

import { ReducerUser } from './../../types/reducers'
import axios from 'axios'
import config from '../../config-cross'
import { Reducers } from '../../types/reducers'
import { setData, setFavorites } from './data'
import { setDataLoader, setFavoritesLoader } from './graphics'
import { db } from '../../Firebase'
import { Video } from '../../types/data'

export const fetchUserVideo: any = (passedUser?: ReducerUser) => async (dispatch: (any: any) => void, getState: () => Reducers) => {
    await dispatch(setDataLoader(true))
    let user = passedUser

    if (!user) {
        user = getState().user
    }

    const data = await axios({
        method: 'GET',
        url: `${config.baseUrlMediaVr}/profile/contents/`,
        headers: { Authorization: `Token ${user.key}` }
    }).then((response: any) => response.data[0])

    await dispatch(setData(data))
    dispatch(setDataLoader(false))
}

export const fetchUserFavorites: any = (passedUser?: ReducerUser) => async (dispatch: (any: any) => void, getState: () => Reducers) => {
    await dispatch(setFavoritesLoader(true))
    let user = passedUser

    if (!user) {
        user = getState().user
    }

    const prefersRef = db.collection('preferiti').where('uid', '==', user.subscription.id)
    const favoriteVideos = (await prefersRef.get().then((snapshot) => {
        if (snapshot.empty) {
            return []
        }

        return snapshot.docs.map((doc) => {
            return doc.data()
        })
    })) as Video[]

    await dispatch(setFavorites(favoriteVideos))
    dispatch(setFavoritesLoader(false))
}

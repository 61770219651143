import React, { useState } from 'react'
import '../resources/css/login.css'
import { db } from '../Firebase'
import { store } from 'react-notifications-component'
import axios from 'axios'
import config from '../config-cross'
import { useDispatch } from 'react-redux'
import { setUser } from '../store/actions/user'
import { ReducerUser } from '../types/reducers'
import logo from '../resources/images/logo.svg'
import { fetchUserFavorites, fetchUserVideo } from '../store/actions/thunk_actions'
import Loader from '../components/Loader'
import { useHistory } from 'react-router-dom'

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState<string>('aime@22hbg.com')
    const [password, setPassword] = useState<string>('aime76')
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const history = useHistory()

    const dispatch = useDispatch()

    const loginUser = async () => {
        setIsSubmitting(true)
        const fetchedUser: ReducerUser = await axios({
            method: 'POST',
            //url:`${config.baseUrlMediaVr}/app-login/`,
            url: `${config.baseUrlMediaVr}/login/`,
            data: {
                email,
                password,
                radio: 'jazz360' // solo se è abilitato login e non app-login
            }
        }).then((response) => response.data)

        const data = {
            ...fetchedUser,
            uid: fetchedUser.subscription.id
        }

        await db
            .collection('users')
            .doc(data.uid.toString())
            .set(data)
            .then(() => {
                store.addNotification({
                    message: 'Benvenuto ' + data.first_name,
                    type: 'warning',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                })
            })
            .catch((error) => {
                store.addNotification({
                    title: 'Login fallito',
                    message: error.message,
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                })
            })
        dispatch(fetchUserVideo(fetchedUser))
        dispatch(fetchUserFavorites(fetchedUser))
        await dispatch(setUser(fetchedUser))
        setIsSubmitting(false)
        history.push({ pathname: '/' })
    }

    const handleEmail = (e: any) => {
        setEmail(e.target.value)
    }

    const handlePassword = (e: any) => {
        setPassword(e.target.value)
    }

    return (
        <div className="mainContainer">
            <img className="logoA" src={logo} />
            <h1 className="titleLogin">Accedi</h1>
            <input className="formLogin" placeholder="Indirizzo E-Mail" type="emailA" value={email} onChange={handleEmail} />
            <input className="formLogin" placeholder="Password" type="password" value={password} onChange={handlePassword} />
            {isSubmitting ? (
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Loader />
                </div>
            ) : (
                <button className="buttonLogin" onClick={() => loginUser()}>
                    ENTRA
                </button>
            )}
        </div>
    )
}

export default LoginPage

import React, { useState } from 'react'
import { useHistory } from 'react-router'
import Modal from './Modal'
import ArtistDetails from './Video/ArtistDetails'

interface Props {
    video: any
    posterUrl: string
    artist: string
}

const ArtistCard: React.FC<Props> = (props) => {
    const [toggleModal, setToggleModal] = useState<boolean>(false)

    const history = useHistory()

    const handleToggleModal = () => {
        const info = props.video.info ? props.video.info.artist_name.replace(' ', '-') : undefined
        history.push({ pathname: '/artist', state: { artistName: info } })
    }

    const closeModal = () => {
        setToggleModal(false)
    }

    const netflixUrl = false

    return (
        <>
            <div onClick={() => handleToggleModal()} className={'movieShowcase__container--movie' + (netflixUrl ? '__netflix' : '')}>
                <img src={props.posterUrl} className="movieShowcase__container--movie-image" />
                <div className="movieShowcase__container--movie-overtitle">
                    <span className="movieShowcase__container--movie-overtitle-name"><b>{props.video.band_name}</b></span>
                </div>
            </div>
            <Modal show={toggleModal} videoImage={''} modalClosed={closeModal} cover={props.posterUrl}>
                <ArtistDetails video={props.video} artist={props.artist} />
            </Modal>
        </>
    )
}

export default ArtistCard

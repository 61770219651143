import { ReduxAction } from '../../types/reducers'

export enum GRAPHICS_ACTIONS {
    SET_DATA_LOADER = 'graphicsActions/setDataLoader',
    SET_FAVORITES_LOADER = 'graphicsActions/setFavoritesLoader'
}

export const setDataLoader: ReduxAction<boolean, GRAPHICS_ACTIONS> = (status) => ({
    type: GRAPHICS_ACTIONS.SET_DATA_LOADER,
    payload: status
})

export const setFavoritesLoader: ReduxAction<boolean, GRAPHICS_ACTIONS> = (status) => ({
    type: GRAPHICS_ACTIONS.SET_FAVORITES_LOADER,
    payload: status
})

import React from 'react'

interface Props {
    posterUrl: string
}

const EventsComp: React.FC<Props> = (props) => {
    const netflixUrl = false

    return (
        <div className={'movieShowcase__container--movie_events' + (netflixUrl ? '__netflix' : '')}>
            <img src={props.posterUrl} className="movieShowcase__container--movie_events-image" />
        </div>
    )
}

export default EventsComp

import React from 'react'
import { useSelector } from 'react-redux'
import ArtistCompTable from '../../components/ArtistCompTable'
import { Reducers, ReducerData } from '../../types/reducers'
import { getRandomImage } from '../../utils/functions'
import ArtistsSection from './ArtistsSection'

const ArtistsPage: React.FC = () => {
    const data = useSelector<Reducers, ReducerData>((store) => store.data)
    const artistArray = []

    const artists: any = {}
    for (let i = 0; i < data.data.all_videos.length; i++) {
        if (!artists[data.data.all_videos[i].info.artist_name]) {
            artists[data.data.all_videos[i].info.artist_name] = []
            artists[data.data.all_videos[i].info.artist_name].push(data.data.all_videos[i])
        }
    }

    let videos
    if (artists !== null) {
        videos = Object.keys(artists).map((key) => {
            return artists[key].map((video: any, i: number) => {
                let artist_url_image = getRandomImage()
                artistArray.push(key)

                if (video.cover.url !== null) {
                    artist_url_image = 'https://mediavr-api.22hbg.net/' + video.cover.url
                }

                return <ArtistCompTable key={i} posterUrl={artist_url_image} video={video} artist={key} />
            })
        })
    }

    return (
        <>
            <div className="containerScroll">
                {/* <div className="artistGrid">{videos}</div> */}
                <ArtistsSection />
            </div>
        </>
    )
}

export default ArtistsPage

import React from 'react'
import VideoCategories from '../../components/VideoCategories'
import { useSelector } from 'react-redux'
import { Reducers, ReducerData } from '../../types/reducers'
import { Video } from '../../types/data'
import Slider from 'react-slick'

const CategoriesSections: React.FC = () => {
    const data = useSelector<Reducers, ReducerData>((store) => store.data)

    const groupedVideos: {
        videos: Video[]
        category: string
    }[] = []

    for (let i = 0; i < data.data.all_videos.length; i++) {
        const video = data.data.all_videos[i]
        for (let j = 0; j < video.categories.length; j++) {
            const foundIndex = groupedVideos.findIndex((group) => group.category === video.categories[j])
            if (foundIndex === -1) {
                groupedVideos.push({
                    videos: [video],
                    category: video.categories[j]
                })
            } else {
                groupedVideos[foundIndex].videos.push(video)
            }
        }
    }

    const videoArr = groupedVideos.map((group) => {
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: group.videos.length > 6 ? 6 : group.videos.length,
            swipeToSlide: false,
            responsive: [
                {
                    breakpoint: 1560,
                    settings: {
                        slidesToShow: group.videos.length > 5 ? 5 : group.videos.length,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: group.videos.length > 4 ? 4 : group.videos.length,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: group.videos.length > 3 ? 3 : group.videos.length,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: group.videos.length > 2 ? 2 : group.videos.length,
                    }
                }
            ]
        };

        return (
            <div key={group.category} className="containerScroll standardVideo">
                <h1 className="movieShowcase__heading">{group.category}</h1>
                <Slider {...settings}>
                    {group.videos.map((vid: any, k: number) => {
                        return <VideoCategories key={k} video={vid} />
                    })}
                </Slider>
            </div>
        )
    })
    return <div>{videoArr}</div>
}

export default CategoriesSections

import React from 'react'
import { useSelector } from 'react-redux'
import { Reducers, ReducerData } from '../../types/reducers'
import ArtistCard from '../../components/ArtistCard'
import { getRandomImage } from '../../utils/functions'
import Slider from "react-slick";

const ArtistsSection: React.FC = () => {
    const data = useSelector<Reducers, ReducerData>((store) => store.data)
    const artistArray = []

    const artists: any = {}
    for (let i = 0; i < data.data.all_videos.length; i++) {
        if (!artists[data.data.all_videos[i].info.artist_name]) {
            artists[data.data.all_videos[i].info.artist_name] = []
            artists[data.data.all_videos[i].info.artist_name].push(data.data.all_videos[i])
        }
    }

    let videos
    if (artists !== null) {
        videos = Object.keys(artists).map((key) => {
            return artists[key].map((video: any, i: number) => {
                let artist_url_image = getRandomImage()
                artistArray.push(key)

                if (video.cover.url !== null) {
                    artist_url_image = 'https://mediavr-api.22hbg.net/' + video.cover.url
                }

                return <ArtistCard key={i} posterUrl={artist_url_image} video={video} artist={key} />
            })
        })
    }


    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: artistArray.length > 6 ? 6 : artistArray.length,
        slidesToScroll: artistArray.length > 6 ? 6 : artistArray.length,
        swipeToSlide: false,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: artistArray.length > 5 ? 5 : artistArray.length,
                    slidesToScroll: artistArray.length > 5 ? 5 : artistArray.length,
                    infinite: true,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: artistArray.length > 4 ? 4 : artistArray.length,
                    slidesToScroll: artistArray.length > 4 ? 4 : artistArray.length,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: artistArray.length > 3 ? 3 : artistArray.length,
                    slidesToScroll: artistArray.length > 3 ? 3 : artistArray.length,
                    initialSlide: artistArray.length > 3 ? 3 : artistArray.length
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: artistArray.length > 2 ? 2 : artistArray.length,
                    slidesToScroll: artistArray.length > 2 ? 2 : artistArray.length,
                }
            }
        ]
    };

    return (
        <>
            <div className="containerScroll">
                <h1 className="movieShowcase__heading momento">Artisti del momento</h1>
                <Slider {...settings}>
                    {videos}
                </Slider>
            </div>
        </>
    )
}

export default ArtistsSection

import { ReduxActionData } from './../../types/reducers'
import { ReducerUser } from '../../types/reducers'
import { USER_ACTIONS } from '../actions/user'

const defaultValue: ReducerUser = {
    key: '',
    first_name: '',
    last_name: '',
    email: '',
    subscription: {
        id: '',
        active: false,
        created: '',
        modified: '',
        payment_service: '',
        renewal_date: '',
        plan: 0,
        plan_name: '',
        radio: 0,
        is_trial: false
    }
}

const userState = (state = defaultValue, action: ReduxActionData<any, USER_ACTIONS>): ReducerUser => {
    switch (action.type) {
        case USER_ACTIONS.SET_USER:
            return {
                ...state,
                ...action.payload
            }
        case USER_ACTIONS.LOGOUT:
            return defaultValue
        default:
            return state
    }
}

export default userState

import React from 'react'
import { useSelector } from 'react-redux'
import { Reducers, ReducerData } from '../../types/reducers'
import TopTenCard from '../../components/TopTenCard'
import Slider from "react-slick";

const TopTenSection: React.FC = () => {
    const data = useSelector<Reducers, ReducerData>((store) => store.data)

    // Clone orinigal array
    const videoTemp = [...data.data.all_videos]
    const videoTop = []

    for (let i = 0; i < 10; i++) {
        if (videoTemp.length !== 0) {
            const randomVideo = videoTemp[Math.floor(Math.random() * videoTemp.length)]
            const index = videoTemp.indexOf(randomVideo)
            videoTemp.splice(index, 1)
            videoTop.push(randomVideo)
        }
    }

    let videos

    if (videoTop !== null) {
        videos = videoTop.map((video: any, i: number) => <TopTenCard position={i+1} key={i} posterUrl={'https://mediavr-api.22hbg.net/' + video.cover.url} video={video} />)
    }

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: videoTop.length > 6 ? 6 : videoTop.length,
        swipeToSlide: false,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: videoTop.length > 5 ? 5 : videoTop.length,
                    infinite: true,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: videoTop.length > 4 ? 4 : videoTop.length,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: videoTop.length > 3 ? 3 : videoTop.length,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: videoTop.length > 2 ? 2 : videoTop.length,
                }
            }
        ]
    };

    return (
        <>
            <div className="containerScroll">
                <h1 className="movieShowcase__heading">Top Ten</h1>
                <Slider {...settings}>
                    {videos}
                </Slider>
            </div>
        </>
    )
}

export default TopTenSection

import { Video } from './../../types/data'
import { ReducerData } from './../../types/reducers'
import { ReduxAction } from '../../types/reducers'

export enum DATA_ACTIONS {
    SET_DATA = 'dataActions/setData',
    SET_FAVORITES = 'dataActions/setFavorites'
}

export const setData: ReduxAction<ReducerData, DATA_ACTIONS> = (data) => ({
    type: DATA_ACTIONS.SET_DATA,
    payload: data
})

export const setFavorites: ReduxAction<Video[], DATA_ACTIONS> = (favorites) => ({
    type: DATA_ACTIONS.SET_FAVORITES,
    payload: favorites
})

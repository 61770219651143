import { ReducerGraphics, ReduxActionData } from './../../types/reducers'
import { GRAPHICS_ACTIONS } from '../actions/graphics'

const defaultValue: ReducerGraphics = {
    dataLoader: true,
    favoritesLoader: true
}

const userState = (state = defaultValue, action: ReduxActionData<any, GRAPHICS_ACTIONS>): ReducerGraphics => {
    switch (action.type) {
        case GRAPHICS_ACTIONS.SET_DATA_LOADER:
            return {
                ...state,
                dataLoader: action.payload
            }
        case GRAPHICS_ACTIONS.SET_FAVORITES_LOADER:
            return {
                ...state,
                favoritesLoader: action.payload
            }
        default:
            return state
    }
}

export default userState

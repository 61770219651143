import React from 'react'

interface Props {
    artist: string
    video: any
    videoDetails: () => void
    videoImage: string
}

const VideoComponent: React.FC<Props> = (props) => (
    <>

        <div onClick={props.videoDetails} className={'movieShowcase__container--movie'}>
            <img src={props.videoImage} className="movieShowcase__container--movie-image" />
            <div className="movieShowcase__container--movie-overtitle">
                <span className="movieShowcase__container--movie-overtitle-cat">{props.video.band_name}</span>
                <span className="movieShowcase__container--movie-overtitle-name">{props.video.video_name}</span>
            </div>
        </div>
    </>
)

export default VideoComponent

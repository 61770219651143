import { Dialog } from '@material-ui/core'
import React from 'react'
import close from '../resources/images/Close.svg';

interface Props {
    cover: string
    show: boolean
    modalClosed: () => void
    videoImage: any
}

const Modal: React.FC<Props> = (props) => {
    let video_image = ''

    if (props.videoImage !== undefined) {
        video_image = props.videoImage.url
    }

    if (!props.show) {
        return null
    }

    return (
        <Dialog open={props.show} onClose={props.modalClosed} fullWidth={true} maxWidth="xl">
            <div
                style={{
                    color: '#fff',
                    backgroundSize: 'cover',
                    backgroundPosition: "center",
                    backgroundImage: `url(${props.cover ? props.cover : 'https://mediavr-api.22hbg.net' + video_image})`,
                    height: 700
                }}
            >
                <img src={close} style={{position: "absolute", width: 20, top: 20, right: 20, cursor: "pointer"}} onClick={props.modalClosed} />
                {props.children}
            </div>
        </Dialog>
    )
}

export default Modal

import { EmptyReduxAction, ReducerUser, ReduxAction } from '../../types/reducers'

export enum USER_ACTIONS {
    SET_USER = 'userActions/setUser',
    LOGOUT = 'userActions/logout'
}

export const setUser: ReduxAction<ReducerUser, USER_ACTIONS> = (user) => {
    return {
        type: USER_ACTIONS.SET_USER,
        payload: user
    }
}

export const logout: EmptyReduxAction<USER_ACTIONS> = () => {
    return {
        type: USER_ACTIONS.LOGOUT
    }
}

import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'
import Modal from '../components/Modal'
import VideoComponent from '../components/Video/VideoComponent'
import VideoDetails from '../components/Video/VideoDetails'
import { Video } from '../types/data'
import { ReducerData, Reducers } from '../types/reducers'

interface Props {
    query: string
}

const SearchPage: React.FC<Props> = (props) => {
    const [toggleModal, setToggleModal] = useState<boolean>(false)
    const [movieOverview, setMovieOverview] = useState<Video | null>(null)

    const data = useSelector<Reducers, ReducerData>((store) => store.data)

    const selectMovieHandler = (video: Video) => {
        setToggleModal(true)
        setMovieOverview(video)
    }

    const closeModal = () => {
        setToggleModal(false)
    }

    const filteredVideos: Video[] = useMemo(() => {
        return data.data.all_videos.filter(
            (video) =>
                video.video_name.toLowerCase().trim().indexOf(props.query.toLowerCase().trim()) > -1 ||
                video.band_name.toLowerCase().trim().indexOf(props.query.toLowerCase().trim()) > -1 ||
                video.event_name.toLowerCase().trim().indexOf(props.query.toLowerCase().trim()) > -1
        )
    }, [props.query])

    const groupedVideos: {
        videos: Video[]
        artist: string
    }[] = []

    for (let i = 0; i < filteredVideos.length; i++) {
        const video = filteredVideos[i]
        const foundIndex = groupedVideos.findIndex((group) => group.artist === video.info.artist_name)
        if (foundIndex === -1) {
            groupedVideos.push({
                videos: [video],
                artist: video.info.artist_name
            })
        } else {
            groupedVideos[foundIndex].videos.push(video)
        }
    }

    const videoArr = groupedVideos.map((group) => {
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: group.videos.length > 5 ? 5 : group.videos.length,
            slidesToScroll: group.videos.length > 5 ? 5 : group.videos.length,
            swipeToSlide: false,
            responsive: [
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: group.videos.length > 4 ? 4 : group.videos.length,
                        slidesToScroll: group.videos.length > 4 ? 4 : group.videos.length,
                        infinite: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: group.videos.length > 3 ? 3 : group.videos.length,
                        slidesToScroll: group.videos.length > 3 ? 3 : group.videos.length,
                        infinite: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: group.videos.length > 2 ? 2 : group.videos.length,
                        slidesToScroll: group.videos.length > 2 ? 2 : group.videos.length,
                        initialSlide: group.videos.length > 2 ? 2 : group.videos.length
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }

        return (
            <div key={group.artist} className="containerScroll standardVideo">
                <h1 className="movieShowcase__heading" style={{ color: '#fff' }}>{group.artist}</h1>
                <Slider {...settings}>
                    {group.videos.map((video: any, i: number) => {
                        return (
                            <VideoComponent
                                videoDetails={() => selectMovieHandler(video)}
                                key={i}
                                videoImage={'https://mediavr-api.22hbg.net' + video.cover.url}
                                video={video}
                                artist={video.info ? video.info.artist_name : ''}
                            />
                        )
                    })}
                </Slider>
            </div>
        )
    })

    return (
        <div className="container">
            <h1 className="movieShowcase__heading" style={{ color: '#fff', fontSize: 36 }}>
                Stai cercando {props.query}...
            </h1>
            {videoArr}

            <Modal
                show={toggleModal}
                modalClosed={closeModal}
                cover={movieOverview ? movieOverview.cover.url : ''}
                videoImage={movieOverview ? movieOverview.cover : ''}
            >
                <VideoDetails video={movieOverview} status={toggleModal} />
            </Modal>
        </div>
    )
}

export default SearchPage

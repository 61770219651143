import React, { useEffect, useRef, useState } from 'react'
import { store } from 'react-notifications-component'
import { db } from '../../Firebase'
import Tabs from '../Tabs'
import { ReducerData, Reducers, ReducerUser } from '../../types/reducers'
import { useDispatch, useSelector } from 'react-redux'
import favoriteIcon from '../../resources/images/favorite.svg'
import noFavoriteIcon from '../../resources/images/no-favorite.svg'
import { Video } from '../../types/data'
import { usePrevious } from '../../utils/hooks'
import { formatVideoUrl, isVideoFavorite } from '../../utils/functions'
import { fetchUserFavorites } from '../../store/actions/thunk_actions'

interface Props {
    video: Video | null
    status: boolean
}

const VideoDetails: React.FC<Props> = (props) => {
    const video = useRef<HTMLVideoElement>(null)
    const videoContainer = useRef<HTMLDivElement>(null)

    const [videoUrls, setVideoUrls] =
        useState<{
            original: string
            converted: string | null
        } | null>(null)

    const user = useSelector<Reducers, ReducerUser>((store) => store.user)
    const data = useSelector<Reducers, ReducerData>((store) => store.data)

    const dispatch = useDispatch()

    useEffect(() => {
        checkUrls()
    }, [])

    const checkUrls = async () => {
        if (!props.video) {
            return
        }

        setVideoUrls(await formatVideoUrl(props.video))
    }

    const previousContainer = usePrevious(videoContainer.current)

    useEffect(() => {
        if (previousContainer !== undefined && previousContainer === null && videoContainer.current !== null) {
            if (videoContainer.current !== null && video.current !== null) {
                video.current.style.height = `${videoContainer.current.getBoundingClientRect().height}px`
            }
        }
    }, [videoContainer.current])

    const addToFavorites = (video: Video) => {
        const urlSubstringPos = video.url.indexOf('libfdk')
        const idArr = video.url.substring(urlSubstringPos, video.url.length).split('/')
        const videoData = {
            videoId: idArr[4],
            ...video,
            uid: user.subscription.id
        }
        db.collection('preferiti')
            .doc(videoData.videoId.toString())
            .set(videoData)
            .then(() => {
                dispatch(fetchUserFavorites())
                store.addNotification({
                    title: 'Lista preferiti',
                    message: 'Aggiunto il video ' + video.video_name + ' alla mia lista',
                    type: 'warning',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                })
            })
            .catch((error) => {
                store.addNotification({
                    title: 'Errore',
                    message: error.message,
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                })
            })
    }

    const removeToFavorites = (video: Video) => {
        const idArr = video.url.substring(video.url.indexOf('libfdk'), video.url.length).split('/')
        const videoData = {
            videoId: idArr[4],
            ...video,
            uid: user.subscription.id
        }
        db.collection('preferiti')
            .doc(videoData.videoId.toString())
            .delete()
            .then(() => {
                dispatch(fetchUserFavorites())
                store.addNotification({
                    title: 'Lista preferiti',
                    message: 'Rimosso il video ' + video.video_name + ' dalla mia lista',
                    type: 'warning',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                })
            })
            .catch((error) => {
                store.addNotification({
                    title: 'Errore',
                    message: error.message,
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                })
            })
    }

    if (props.video === null) {
        return null
    }

    const membersArray: any[] = []
    if (props.video.info) {
        props.video.info.members.map((member: any, i: number) => {
            return membersArray.push(
                <li className="modal__dataCol-dataCont--description" key={i}>
                    {member.name.toUpperCase()} {' - '} {member.role}
                </li>
            )
        })
    }

    const getCurrentVideo = () => {
        if (videoUrls) {
            if (videoUrls.converted) {
                return videoUrls.converted
            }

            return videoUrls.original
        }
        return ''
    }

    return (
        <div className="modal__container">
            {props.status && videoUrls !== null && (
                <div ref={videoContainer} className="modal__videoCol">
                    <dl8-video ref={video} author="Cross360" format="STEREO_360_TB" preload="auto" fps={videoUrls.converted === null ? '60' : '30'}>
                        <source src={getCurrentVideo()} type="video/mp4" />
                    </dl8-video>
                </div>
            )}
            <div className="modal__dataCol">
                <div className="modal__dataCol-dataCont">
                    <div className="modal__dataCol-dataCont--metaCont">
                        <div className="modal__dataCol-dataCont--metaCont---titleCont">
                            <h2 className="modal__dataCol-dataCont--metaCont---titleCont----catTitle">
                                {props.video.info && props.video.info.artist_name ? props.video.info.artist_name : ''}
                            </h2>
                            <h1 className="modal__dataCol-dataCont--metaCont---titleCont----title">{props.video.video_name}</h1>
                        </div>
                        <div className="modal__dataCol-dataCont--buttons">
                            {isVideoFavorite(props.video, data) ? (
                                // @ts-ignore
                                <button className="buttonAdd" onClick={() => removeToFavorites(props.video)}>
                                    <img src={favoriteIcon} />
                                </button>
                            ) : (
                                // @ts-ignore
                                <button className="buttonAdd" onClick={() => addToFavorites(props.video)}>
                                    <img src={noFavoriteIcon} />
                                </button>
                            )}
                        </div>
                    </div>
                    <div style={{ clear: 'both' }} />
                    <Tabs
                        id="videoDetails"
                        tabs={[
                            {
                                label: 'Descrizione',
                                content: (
                                    <div>
                                        <p className="modal__dataCol-dataCont--description">
                                            {props.video.info && props.video.info.text
                                                ? props.video.info.text.split(/(?:\r\n|\r|\n)/g).map((p) => {
                                                      return (
                                                          <>
                                                              {p}
                                                              <br />
                                                          </>
                                                      )
                                                  })
                                                : ''}
                                        </p>
                                    </div>
                                )
                            },
                            {
                                label: 'Band',
                                content: <div>{membersArray}</div>
                            },
                            {
                                label: 'Location',
                                content: (
                                    <div>
                                        <p className="modal__dataCol-dataCont--description">
                                            {props.video.info && props.video.info.date ? props.video.info.date : ''}
                                        </p>
                                        <p className="modal__dataCol-dataCont--description">
                                            {props.video.info && props.video.info.city ? props.video.info.city : ''}
                                        </p>
                                        <p className="modal__dataCol-dataCont--description">
                                            {props.video.info && props.video.info.location ? props.video.info.location : ''}
                                        </p>
                                    </div>
                                )
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    )
}
export default VideoDetails

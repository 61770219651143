import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import NavigationItem from './NavigationItem'
import { logout } from '../store/actions/user'
import dropDownArrowIcon from '../resources/images/drop-down-arrow.svg'
import searchIcon from '../resources/images/search-icon.svg'
import loginIcon from '../resources/images/login.svg'
import logoIcon from '../resources/images/logo.png'

interface Props {
    onSearch: React.ChangeEventHandler<HTMLInputElement>
}

const Navbar: React.FC<Props> = (props) => {
    const [scrolling, setScrolling] = useState<boolean>(false)

    const history = useHistory()

    const dispatch = useDispatch()

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    const handleScroll = () => {
        if (window.scrollY === 0) {
            setScrolling(false)
        } else if (window.scrollY > 50) {
            setScrolling(true)
        }
    }

    return (
        <nav className={'navigation ' + (scrolling ? 'black' : '')}>
            <ul className="navigation__container">
                <NavigationItem link="/" exact>
                    <img className="navigation__container--logo" src={logoIcon} alt="" />
                </NavigationItem>
                <img src={dropDownArrowIcon} className="navigation__container--downArrow-2" />
                <NavigationItem link="/" exact>
                    Home
                </NavigationItem>
                <NavigationItem link="/eventi" exact>
                    Eventi
                </NavigationItem>
                <NavigationItem link="/artisti" exact>
                    Artisti
                </NavigationItem>
                <NavigationItem link="/lista" exact>
                    La mia lista
                </NavigationItem>
                <span
                    className="navigation__container-link"
                    style={{
                        cursor: 'pointer'
                    }}
                    onClick={() => dispatch(logout())}
                >
                    Logout
                </span>

                <div className="navigation__container--left">
                    <img src={searchIcon} className="logo" />
                    <input onChange={props.onSearch} className="navigation__container--left__inputscss" type="text" placeholder="Cerca..." />
                </div>

                <img
                    src={loginIcon}
                    className="navigation__container--loginLogo"
                    onClick={() => {
                        history.push('/account')
                    }}
                />
            </ul>
        </nav>
    )
}

export default Navbar

import React, { useState } from 'react'
import { getRandomImage } from '../utils/functions'

import Modal from './Modal'
import VideoDetails from './Video/VideoDetails'

interface Props {
    video: any
}

const VideoCategories: React.FC<Props> = (props) => {
    const [toggleModal, setToggleModal] = useState<boolean>(false)

    const handleToggleModal = () => {
        setToggleModal(true)
    }

    const closeModal = () => {
        setToggleModal(false)
    }

    let image = getRandomImage('..')
    if (props.video.cover.url !== null && props.video.cover.url !== 'https://mediavr-api.22hbg.netnull') {
        image = 'https://mediavr-api.22hbg.net' + props.video.cover.url
    }

    return (
        <>
            <div onClick={() => handleToggleModal()} className={'movieShowcase__container--movie'}>
                <img src={image} className="movieShowcase__container--movie-image" />
                <div className="movieShowcase__container--movie-overtitle">
                    <span className="movieShowcase__container--movie-overtitle-cat">{props.video.event_name}</span>
                    <span className="movieShowcase__container--movie-overtitle-name">
                        <b>{props.video.band_name}</b>
                    </span>
                    <span className="movieShowcase__container--movie-overtitle-name">
                        {props.video.video_name}
                    </span>
                    {/*<span className="movieShowcase__container--movie-overtitle-name"></span>*/}
                </div>
            </div>
            <div></div>
            <Modal show={toggleModal} videoImage={props.video} modalClosed={closeModal} cover={image}>
                <VideoDetails video={props.video} status={toggleModal} />
            </Modal>
        </>
    )
}
export default VideoCategories

import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Video } from '../types/data'

import Modal from './Modal'
import ArtistDetails from './Video/ArtistDetails'

interface Props {
    video: Video
    posterUrl: string
    artist: string
}

const ArtistCompTable: React.FC<Props> = (props) => {
    const [toggleModal, setToggleModal] = useState<boolean>(false)

    const history = useHistory()

    const handleToggleModal = () => {
        if (!props.video.info) {
            return
        }

        const artistName = props.video.info.artist_name.replace(' ', '-')
        history.push({ pathname: '/artist', state: { artistName } })
    }

    const closeModal = () => {
        setToggleModal(false)
    }

    const netflixUrl = false
    return (
        <>
            <div onClick={() => handleToggleModal()} className={'movieShowcase__container--movie' + (netflixUrl ? '__netflix' : '')}>
                <img src={props.posterUrl} className="movieShowcase__container--movie-image" />
                <div className="movieShowcase__container--movie-overtitle">
                    <span className="movieShowcase__container--movie-overtitle-name">{props.video.band_name}</span>
                </div>
            </div>
        </>
    )
}

export default ArtistCompTable

import { ReducerData, ReduxActionData } from './../../types/reducers'
import { DATA_ACTIONS } from '../actions/data'

const defaultValue: ReducerData = {
    data: {
        all_videos: [],
        cover: {
            timestamp: '',
            url: ''
        },
        id: '',
        is_subscription_valid: false,
        mainstreaming_token: '',
        name: ''
    },
    favorites: []
}

const userState = (state = defaultValue, action: ReduxActionData<any, DATA_ACTIONS>): ReducerData => {
    switch (action.type) {
        case DATA_ACTIONS.SET_DATA:
            return {
                ...state,
                data: action.payload
            }
        case DATA_ACTIONS.SET_FAVORITES:
            return {
                ...state,
                favorites: action.payload
            }
        default:
            return state
    }
}

export default userState

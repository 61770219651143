import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { Reducers, ReducerUser } from './types/reducers'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserFavorites, fetchUserVideo } from './store/actions/thunk_actions'
import LoginPage from './pages/LoginPage'
import '../node_modules/slick-carousel/slick/slick.css'
import '../node_modules/slick-carousel/slick/slick-theme.css'
import Header from './components/Header'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import SearchPage from './pages/SearchPage'
import ArtistsPage from './pages/home/ArtistsPage'
import ArtistPage from './pages/ArtistPage'
import EventsPage from './pages/EventsPage'
import FavoritesPage from './pages/FavoritesPage'
import HomePage from './pages/home/HomePage'
import Account from './pages/AccountPage'
import { isMobile } from 'react-device-detect'
import LOGO from './resources/images/Jazz360.svg'
import APPSTORE from './resources/images/app-store.png'
import PLAYSTORE from './resources/images/play-store.png'

const App: React.FC = () => {
    const [query, setQuery] = useState<string>('')
    const user = useSelector<Reducers, ReducerUser>((store) => store.user)

    const dispatch = useDispatch()

    useEffect(() => {
        if (user.key !== '') {
            dispatch(fetchUserVideo())
            dispatch(fetchUserFavorites())
        }
    }, [])

    if (isMobile) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', padding: 20 }}>
                <img src={LOGO} style={{ width: '55vw' }} />
                <h1 style={{ fontSize: 30, color: '#fff', fontWeight: 400, margin: 20 }}>Scarica ora l&apos;app</h1>
                <img src={APPSTORE} style={{ width: '80vw', marginBottom: 20 }} />
                <img src={PLAYSTORE} style={{ width: '80vw' }} />
            </div>
        )
    }

    return (
        <BrowserRouter>
            {user.key === '' ? (
                <LoginPage />
            ) : (
                <div className="container">
                    <Navbar onSearch={(e: any) => setQuery(e.target.value)} />
                    {query === '' ? (
                        <>
                            <Header />
                            <div className="movieShowcase">
                                <Route path="/" component={HomePage} exact />
                                <Route path="/artisti" component={ArtistsPage} />
                                <Route path="/eventi" component={EventsPage} />
                                <Route path="/artist" component={ArtistPage} />
                                <Route path="/lista" component={FavoritesPage} />
                                <Route path="/account" component={Account} />
                            </div>
                        </>
                    ) : (
                        <SearchPage query={query} />
                    )}

                    <Footer />
                </div>
            )}
        </BrowserRouter>
    )
}

export default App
